import * as React from "react";
import { BlogContents, BlogContainer, Breadcrumb } from "../../components";
import Seo from "../../components/Seo";

const BlogPage = () => {
  return (
    <BlogContainer>
      <Seo
        title="TKC Holding Blog"
        description="TKCが運営するブログです。主にトレンド技術や、プログラミングに関するナレッジ、社内のイベントやカルチャー発信、BiblioTechなど自社サービスの紹介も行っています。"
      />
      <BlogContents />
      <Breadcrumb currentPage="Blog" currentSlug="/blog" />
    </BlogContainer>
  );
};

export default BlogPage;
